import throttle from 'lodash.throttle';

export default class Topbar {
  constructor() {
    document.addEventListener('animationstart', (e) => {
      if (e.animationName == 'topbarNodeReady') {
        this.init();
      }
    });

    document.addEventListener('DOMContentLoaded', () => {
      this.init();
    });
  }

  init() {
    if (!this.initialized) {
      this.initialized = true;
      this.topbar = document.querySelector('[data-topbar]');
      if (this.topbar) {
        this.initDynamicHeight();
      }
    }
  }

  initDynamicHeight() {
    this.setDynamicHeight();
    window.addEventListener('resize', throttle(this.setDynamicHeight.bind(this), 100));
    document.addEventListener('fonts-sans-loaded', this.setDynamicHeight.bind(this));
  }

  setDynamicHeight() {
    document.documentElement.style.setProperty('--topbar-height', this.topbar.offsetHeight);
  }
}
